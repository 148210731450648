/* with kirby php templates it should be ok to run purgecss on all but if you run into problems that could happen with frameworks like Nuxt.js consult https://tailwindcss.com/docs/controlling-file-size/#app */
@tailwind base;
html {
  font-size: 12px;
}
@media screen and (min-width: 768px) {
  html {
    font-size: 18px;
  }
}
@font-face {
  font-family: Whyte;
  font-display: swap;
  src: url("../fonts/WhyteInktrap-Regular.woff2");
}
@font-face {
  font-family: Whyte;
  font-display: swap;
  src: url("../fonts/WhyteInktrap-Italic.woff2");
  font-style: italic;
}

.crop-top {
  margin-top: -0.15em;
}
.top-header {
  top: 92px;
}
.text-headline {
  @apply text-3xl;
}
.text-headline__home {
  font-size: 2.8125rem;
}
.swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
}
.responsive-iframe {
  position: relative;
  padding-bottom: 56.25%; // 16:9 aspect ratio
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// create variations for different aspect ratios
.responsive-iframe--4\:3 {
  padding-bottom: 75%; // 4:3 aspect ratio
}

//Page-Transition
.transition-fade {
  transition: 0.3s;
  // transform: translateY(0);
  opacity: 1;
}

html.is-animating .transition-fade {
  // transform: translateY(-4px);
  opacity: 0;
}
::selection {
  background: yellow;
}

.gallery-home-wrapper {
  height: 60vh;
  margin-top: -25vh;
  overflow: hidden;
}

.js-header {
  height: calc(100vh - 7rem);
  // overflow-x: hidden;
}
.swiper-container {
  height: 100vw;
}
.swiper-slide img {
  height: 95vw;
}
.js-header {
  .js-header__nav {
    width: 200vw;
  }
}
.article {
  li {
    list-style-type: disc;
    @apply ml-5 max-w-lg;
  }
  p,
  li {
    @apply mb-4;
  }
  h2,
  h3,
  h4 {
    @apply uppercase;
  }
  h2:not(:first-of-type) {
    @apply mt-8;
  }
  // h3 {
  //   @apply mt-8;
  // }
}
@screen md {
  * {
    cursor: url("../img/cursor.svg") 15 15, auto;
  }
  .text-headline {
    @apply text-4xl;
  }
  .js-header {
    height: calc(100vh - 2.5rem);
  }
  a:not(.no-hover):hover {
    font-style: italic;
  }
  .swiper-container,
  .swiper-slide {
    height: calc(100vh - 180px);
  }
  .swiper-slide img {
    height: calc(100vh - 280px);
  }
  .gallery-home-wrapper {
    margin-top: -25vh;
    height: 64vh;
    overflow: hidden;
  }
}
//Swiper
@import "node_modules/swiper/swiper.scss";
@import "node_modules/swiper/components/effect-fade/effect-fade.scss";
// @import "node_modules/swiper/components/controller/controller.scss";
// @import "node_modules/swiper/components/navigation/navigation.scss";
// @import "node_modules/swiper/components/a11y/a11y.scss";
// @import "node_modules/swiper/components/effect-coverflow/effect-coverflow.scss";
// @import "node_modules/swiper/components/effect-cube/effect-cube.scss";
// @import "node_modules/swiper/components/effect-flip/effect-flip.scss";
@import "node_modules/swiper/components/lazy/lazy.scss";
// @import "node_modules/swiper/components/pagination/pagination.scss";
// @import "node_modules/swiper/components/scrollbar/scrollbar.scss";
// @import "node_modules/swiper/components/thumbs/thumbs.scss";
// @import "node_modules/swiper/components/zoom/zoom.scss";

@tailwind components;
@tailwind utilities;
